import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { CommonRequest } from '../types';

export const VOTE_COMMENT_REQUEST = 'comment/VOTE_COMMENT_REQUEST';
export const VOTE_COMMENT_SUCCESS = 'comment/VOTE_COMMENT_SUCCESS';
export const VOTE_COMMENT_FAILURE = 'comment/VOTE_COMMENT_FAILURE';

export const voteType = {
  UP: 'up',
  DOWN: 'down',
  UNVOTE: 'unvote',
};

interface VoteCommentApiData {
  isUpvoted: boolean;
  isDownvoted: boolean;
  upvoteCount: number;
  downvoteCount: number;
}

interface VoteCommentRequest {
  postId: string;
  commentId: string;
  voteType: string;
}

interface VoteCommentFailure {
  postId: string;
  commentId: string;
}

interface VoteCommentSuccess extends VoteCommentApiData {
  postId: string;
  commentId: string;
}

export const voteCommentRequest = createAction(
  VOTE_COMMENT_REQUEST,
  ({ postId, commentId, voteType }: VoteCommentRequest) => ({
    payload: { _id: commentId, postId, voteType },
  }),
);
export const voteCommentFailure = createAction(
  VOTE_COMMENT_FAILURE,
  ({ postId, commentId }: VoteCommentFailure) => ({
    payload: { _id: commentId, postId },
  }),
);
export const voteCommentSuccess = createAction(
  VOTE_COMMENT_SUCCESS,
  ({
    postId,
    commentId,
    isUpvoted,
    isDownvoted,
    upvoteCount,
    downvoteCount,
  }: VoteCommentSuccess) => ({
    payload: { _id: commentId, postId, isUpvoted, isDownvoted, upvoteCount, downvoteCount },
  }),
);

function voteComment({
  postId,
  commentId,
  voteType,
}: {
  postId: string;
  commentId: string;
  voteType: string;
}) {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { request }: { request: CommonRequest },
  ) => {
    dispatch(voteCommentRequest({ postId, commentId, voteType }));

    const promise = request.put<VoteCommentApiData>(`/votes/comment/${commentId}/${voteType}`, {});
    return promise
      .then(data => dispatch(voteCommentSuccess({ postId, commentId, ...data })))
      .catch(() => dispatch(voteCommentFailure({ postId, commentId })))
      .then(() => promise);
  };
}

export function upvoteComment({ postId, commentId }: { postId: string; commentId: string }) {
  return voteComment({ postId, commentId, voteType: voteType.UP });
}

export function downvoteComment({ postId, commentId }: { postId: string; commentId: string }) {
  return voteComment({ postId, commentId, voteType: voteType.DOWN });
}

export function unvoteComment({ postId, commentId }: { postId: string; commentId: string }) {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { request }: { request: CommonRequest },
  ) => {
    dispatch(voteCommentRequest({ postId, commentId, voteType: voteType.UNVOTE }));

    const promise = request.delete<VoteCommentApiData>(`/votes/comment/${commentId}`);
    return promise
      .then(data => dispatch(voteCommentSuccess({ postId, commentId, ...data })))
      .catch(() => dispatch(voteCommentFailure({ postId, commentId })))
      .then(() => promise);
  };
}
