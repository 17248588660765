import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { CommonRequest } from '../types';

interface Report {
  postId: string;
  commentId: string;
  reportType: string;
}

interface ReportCommentSuccess {
  _id: string;
  reportType: string;
}

export const REPORT_COMMENT_REQUEST = 'comment/REPORT_REQUEST';
export const REPORT_COMMENT_SUCCESS = 'comment/REPORT_SUCCESS';
export const REPORT_COMMENT_FAILURE = 'comment/REPORT_FAILURE';

export const reportCommentRequest = createAction(REPORT_COMMENT_REQUEST);
export const reportCommentSuccess = createAction<ReportCommentSuccess>(REPORT_COMMENT_SUCCESS);
export const reportCommentFailure = createAction(REPORT_COMMENT_FAILURE);

export function reportComment(report: Report) {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { request }: { request: CommonRequest },
  ) => {
    dispatch(reportCommentRequest());

    const promise = request.post(`/posts/${report.postId}/comments/${report.commentId}/report`, {
      reportType: report.reportType,
    });

    return promise
      .then(() =>
        dispatch(
          reportCommentSuccess({
            _id: report.commentId,
            reportType: report.reportType,
          }),
        ),
      )
      .catch(() => dispatch(reportCommentFailure()))
      .then(() => promise);
  };
}
