import { SET_PAGER_STATE } from '../actions/comments-pagination-actions';

export function commentsPagination(state = {}, action) {
  switch (action.type) {
    case SET_PAGER_STATE:
      return { ...state, [action.payload.resourceId]: { ...action.payload } };
    default:
      return state;
  }
}
