export const MOVE_POST_REQUEST = 'post/MOVE_REQUEST';
export const MOVE_POST_SUCCESS = 'post/MOVE_SUCCESS';
export const MOVE_POST_FAILURE = 'post/MOVE_FAILURE';

export function movePost(id, categoryId) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: MOVE_POST_REQUEST,
    });

    const promise = request.post(`/posts/${id}/move`, { categoryId });

    return promise
      .then(post =>
        dispatch({
          type: MOVE_POST_SUCCESS,
          payload: post,
        }),
      )
      .catch(() =>
        dispatch({
          type: MOVE_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
