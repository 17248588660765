import createAction from '../services/create-action.js';

export const FETCH_CATEGORIES_GROUPS_REQUEST = 'categories/FETCH_GROUPS_REQUEST';
export const FETCH_CATEGORIES_GROUPS_SUCCESS = 'categories/FETCH_GROUPS_SUCCESS';
export const FETCH_CATEGORIES_GROUPS_FAILURE = 'categories/FETCH_GROUPS_FAILURE';

export const fetchCategoriesGroupsRequest = createAction(FETCH_CATEGORIES_GROUPS_REQUEST);
export const fetchCategoriesGroupsSuccess = createAction(
  FETCH_CATEGORIES_GROUPS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchCategoriesGroupsFailure = createAction(FETCH_CATEGORIES_GROUPS_FAILURE);

export const fetchCategoriesGroups = () => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchCategoriesGroupsRequest());
    const promise = request('/categories/all');

    return promise
      .then(
        categories =>
          dispatch(
            fetchCategoriesGroupsSuccess(
              categories.reduce((result, { _id, groups = [] }) => {
                result[_id] = groups;
                return result;
              }, {}),
            ),
          ),
        () => dispatch(fetchCategoriesGroupsFailure()),
      )
      .then(() => promise);
  };
};
