import createAction from '../../common/services/create-action';

export const MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_ADD =
  'manageCategoryMembers/TOGGLE_SITE_MEMBER_ADD';
export const MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_REMOVE =
  'manageCategoryMembers/TOGGLE_SITE_MEMBER_REMOVE';

export const manageCategoryMembersAddSiteMembers = createAction(
  MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_ADD,
  (groupId, listOfSiteMemberIds) => ({ groupId, listOfSiteMemberIds }),
);
export const manageCategoryMembersRemoveSiteMembers = createAction(
  MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_REMOVE,
  (groupId, listOfSiteMemberIds) => ({ groupId, listOfSiteMemberIds }),
);
