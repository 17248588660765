import createAction from '../../common/services/create-action';

export const INCREMENT_POST_VIEW_COUNT_REQUEST = 'post/INCREMENT_VIEW_COUNT_REQUEST';
export const INCREMENT_POST_VIEW_COUNT_SUCCESS = 'post/INCREMENT_VIEW_COUNT_SUCCESS';
export const INCREMENT_POST_VIEW_COUNT_FAILURE = 'post/INCREMENT_VIEW_COUNT_FAILURE';

const incrementPostViewCountRequest = createAction(INCREMENT_POST_VIEW_COUNT_REQUEST);
const incrementPostViewCountSuccess = createAction(INCREMENT_POST_VIEW_COUNT_SUCCESS);
const incrementPostViewCountFailure = createAction(INCREMENT_POST_VIEW_COUNT_FAILURE);

export function incrementPostViewCount(postId) {
  return (dispatch, getState, { request }) => {
    dispatch(incrementPostViewCountRequest({ postId }));

    const promise = request.post(`/posts/${postId}/views`);
    return promise
      .then(
        () => dispatch(incrementPostViewCountSuccess({ postId })),
        error => dispatch(incrementPostViewCountFailure({ postId }, error)),
      )
      .then(() => promise);
  };
}
