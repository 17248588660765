import createAction from '../../common/services/create-action.js';

export const UPDATE_FORUM_DATA_REQUEST = 'forumData/UPDATE_REQUEST';
export const UPDATE_FORUM_DATA_SUCCESS = 'forumData/UPDATE_SUCCESS';
export const UPDATE_FORUM_DATA_FAILURE = 'forumData/UPDATE_FAILURE';

export const updateForumDataRequest = createAction(UPDATE_FORUM_DATA_REQUEST);
export const updateForumDataSuccess = createAction(UPDATE_FORUM_DATA_SUCCESS);
export const updateForumDataFailure = createAction(UPDATE_FORUM_DATA_FAILURE);

export function updateForumData(data = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(updateForumDataRequest());
    const promise = request.patch('/forums/this', data);

    return promise
      .then(post => dispatch(updateForumDataSuccess(post)))
      .catch(() => dispatch(updateForumDataFailure()))
      .then(() => promise);
  };
}

/* This is for internal use only, a quick way to disable posting in a spammed forum,
permissions token is something you can get in fryingpan
*/
export function togglePosting({ isPostingDisabled = true, permissionsToken }) {
  return (dispatch, getState, { request }) => {
    dispatch(updateForumDataRequest());
    const promise = request.patch('/forums/this/read-only', {
      isPostingDisabled,
      token: permissionsToken,
    });

    return promise
      .then(post => dispatch(updateForumDataSuccess(post)))
      .catch(() => dispatch(updateForumDataFailure()))
      .then(() => promise);
  };
}
