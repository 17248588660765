import { POST_MOVE } from '../../messages/message-types';
import { movePost } from '../../../actions/move-post';
import { getCategory } from '../../../../common/selectors/categories-selectors';
import { getPost } from '../../../selectors/post-selectors';
import { navigateWithinForum } from '../../../../common/actions/navigate-within-forum';
import { userEventsMovePost } from '../../../actions/user-events';

export const movePostModalResolver = ({ postId, categoryId }, { showMessage, origin }) => (
  dispatch,
  getState,
) => {
  const sourceCategoryId = getPost(getState(), postId).categoryId;
  return dispatch(movePost(postId, categoryId))
    .then(() => {
      const state = getState();
      const category = getCategory(state, categoryId);
      const post = getPost(state, postId);
      dispatch(userEventsMovePost({ categoryId, origin, postId, sourceCategoryId }));
      dispatch(navigateWithinForum(`/${category.slug}/${post.slug}`));
    })
    .then(() => showMessage(POST_MOVE));
};
