import { find } from 'lodash';
import { ADD_TYPING_USER, REMOVE_TYPING_USER, CLEAR_TYPING_USERS } from '../actions/typing-socket';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TYPING_USER:
      if (find(state, user => user.siteMemberId === action.payload.siteMemberId)) {
        return state;
      }
      return [action.payload, ...state];
    case REMOVE_TYPING_USER:
      return [
        ...state.filter(user => {
          return user.siteMemberId !== action.payload.siteMemberId;
        }),
      ];
    case CLEAR_TYPING_USERS:
      return [];
    default:
      return state;
  }
};
