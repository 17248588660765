export const createExternalHook = ({ timeout = 5000 } = {}) => {
  let hook;
  return {
    setHook: externalHook => {
      hook = externalHook;
    },
    hasHook: () => hook !== undefined,
    exec: (...args) => {
      if (hook === undefined) {
        console.error('No hook defined, you have an error in code');
      }

      return Promise.race([
        hook(...args),
        new Promise(res => setTimeout(() => res({ isExternalHookTimeout: true }), timeout)),
      ]).then(
        payload =>
          Promise.resolve({
            [payload && payload.isExternalHookTimeout
              ? 'isExternalHookTimeout'
              : 'isExternalHookResolve']: true,
            payload,
          }),
        // eslint-disable-next-line prefer-promise-reject-errors
        payload => Promise.reject({ isExternalHookReject: true, payload }),
      );
    },
  };
};

export const isExternalHookTimeout = r => r && r.isExternalHookTimeout;
export const isExternalHookResolve = r => r && r.isExternalHookResolve;
export const isExternalHookReject = r => r && r.isExternalHookReject;
