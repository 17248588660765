import {
  SET_COMMENT_DEEP_LINK_URL,
  RESET_COMMENT_DEEP_LINK_URL,
} from '../actions/comment-deep-link-actions';

export const deepLinkRouting = (state = { comment: null, post: null }, action) => {
  switch (action.type) {
    case SET_COMMENT_DEEP_LINK_URL:
      return {
        ...state,
        ...{ comment: action.payload },
      };
    case RESET_COMMENT_DEEP_LINK_URL:
      return {
        ...state,
        ...{ comment: null },
      };
    default:
      return state;
  }
};
