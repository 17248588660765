import { SHOW_GLOBAL_LOADER, HIDE_GLOBAL_LOADER } from '../actions/global-loader-actions';

export function globalLoader(state = { isVisible: false }, action) {
  switch (action.type) {
    case SHOW_GLOBAL_LOADER:
      return {
        ...state,
        isVisible: true,
      };
    case HIDE_GLOBAL_LOADER:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
}
