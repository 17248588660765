import {
  ADD_ITEM_TO_UPLOADED_REGISTRY,
  REMOVE_ITEM_FROM_UPLOADED_REGISTRY,
} from '../actions/uploaded-registry-actions';

export function uploadedRegistry(state = {}, action) {
  switch (action.type) {
    case ADD_ITEM_TO_UPLOADED_REGISTRY:
      return { ...state, [action.payload.uploadId]: action.payload.uploadData };
    case REMOVE_ITEM_FROM_UPLOADED_REGISTRY:
      const nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
}
