import { createAction } from '@reduxjs/toolkit';

export const FILE_UPLOADED = 'file-upload-plugin/UPLOADED';
export const FILE_DOWNLOAD_REQUEST = 'file-upload-plugin/DOWNLOAD_REQUEST';

interface FileUploaded {
  fileId: string;
  fileExtension: string;
  mimeType: string;
  origin: string;
  size: number;
  isSuccessful: boolean;
}

interface FileDownloadRequest {
  fileId: string;
  fileExtension: string;
  mimeType: string;
  size: number;
  origin: string;
  isSuccessful: boolean;
}

export const fileUploaded = createAction<FileUploaded>(FILE_UPLOADED);

export const fileDownloadRequest = createAction<FileDownloadRequest>(FILE_DOWNLOAD_REQUEST);
