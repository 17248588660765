import createAction from '../../common/services/create-action';

export const FETCH_COMMENT_INDEX_REQUEST = 'FETCH_COMMENT_INDEX_REQUEST';
export const FETCH_COMMENT_INDEX_SUCCESS = 'FETCH_COMMENT_INDEX_SUCCESS';
export const FETCH_COMMENT_INDEX_FAILURE = 'FETCH_COMMENT_INDEX_FAILURE';

export const fetchCommentIndexRequest = createAction(FETCH_COMMENT_INDEX_REQUEST);
export const fetchCommentIndexSuccess = createAction(FETCH_COMMENT_INDEX_SUCCESS);
export const fetchCommentIndexFailure = createAction(FETCH_COMMENT_INDEX_FAILURE);

export const fetchCommentIndex = commentId => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchCommentIndexRequest());

    const promise = request(`/posts/comments/${commentId}/index`);
    return promise
      .then(
        res => dispatch(fetchCommentIndexSuccess(res)),
        () => dispatch(fetchCommentIndexFailure()),
      )
      .then(() => promise);
  };
};
