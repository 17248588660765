import { UPDATE_COMMENT } from '../../../constants/interactions';
import { updateComment } from '../../../actions/update-comment';

export const commentEditModalResolver = updatedComment => (
  dispatch,
  getState,
  { fedopsLogger },
) => {
  fedopsLogger.interactionStarted(UPDATE_COMMENT);
  dispatch(updateComment(updatedComment));
};
