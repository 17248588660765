import createAction from '../../common/services/create-action.js';
import { promoteSubcategories } from './promote-subcategories';
import { getCategories } from '../../common/selectors/categories-selectors.js';

export const DELETE_CATEGORY_REQUEST = 'category/DELETE_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'category/DELETE_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'category/DELETE_FAILURE';

export const deleteCategoryRequest = createAction(DELETE_CATEGORY_REQUEST);
export const deleteCategorySuccess = createAction(
  DELETE_CATEGORY_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const deleteCategoryFailure = createAction(DELETE_CATEGORY_FAILURE);

export function deleteCategory(categoryId, emitChannels = []) {
  return (dispatch, getState, { request, getRouter }) => {
    const isSingleCategoryAfterDelete = getCategories(getState()).length === 2;
    const promise = dispatch(promoteSubcategories(categoryId));
    return promise
      .then(
        () => {
          dispatch(deleteCategoryRequest());
          const promise = request.delete(`/categories/${categoryId}`);

          return promise
            .then(
              () => {
                dispatch(
                  deleteCategorySuccess(categoryId, {
                    publish: [...emitChannels],
                  }),
                );
                if (isSingleCategoryAfterDelete) {
                  // if we go from 2 categories to 1, then the view on homepage changes (we show that 1 category).
                  // we want to trigger navigation so all necessary state is fetched.
                  getRouter().match('/');
                }
              },
              () => dispatch(deleteCategoryFailure()),
            )
            .then(() => promise);
        },
        () => dispatch(deleteCategoryFailure()),
      )
      .then(() => promise);
  };
}
