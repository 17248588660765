import { UPDATE_COMMENT_FAILURE } from '../../actions/update-comment';
import { CREATE_COMMENT_FAILURE, CREATE_COMMENT_SUCCESS } from '../../actions/create-comment';
import { CREATE_POST_FAILURE, CREATE_POST_SUCCESS } from '../../actions/create-post';
import { UPDATE_POST_FAILURE } from '../../actions/update-post';
import {
  POST_INVALID,
  COMMENT_INVALID,
  POST_LIKE_SUCCESS,
  COMMENT_INCREMENT_LIKE_COUNT_SUCCESS,
  SEARCH_SEARCH_SUCCESS,
  POST_CREATE_SUCCESS,
  COMMENT_CREATE_SUCCESS,
  CATEGORY_RANKS_UPDATE_SUCCESS,
  POST_TOO_LARGE,
  COMMENT_TOO_LARGE,
  POST_LIMIT_REACHED,
  COMMENT_LIMIT_REACHED,
} from './message-types';
import { INCREMENT_POST_LIKE_COUNT_SUCCESS } from '../../../common/actions/increment-post-like-count';
import { INCREMENT_COMMENT_LIKE_COUNT_SUCCESS } from '../../actions/increment-comment-like-count';
import { FETCH_SEARCH_SUCCESS } from '../../actions/fetch-search';
import { UPDATE_CATEGORY_RANKS_SUCCESS } from '../../actions/update-category-ranks';

export default {
  [CREATE_POST_FAILURE]: action => {
    if (action.meta.status === 412) {
      return POST_INVALID;
    }
    if (action.meta.status === 413) {
      return POST_TOO_LARGE;
    }
    if (action.meta.status === 429) {
      return POST_LIMIT_REACHED;
    }
  },
  [UPDATE_POST_FAILURE]: action => {
    if (action.meta.status === 412) {
      return POST_INVALID;
    }
    if (action.meta.status === 413) {
      return POST_TOO_LARGE;
    }
  },
  [CREATE_COMMENT_FAILURE]: action => {
    if (action.meta.status === 412) {
      return COMMENT_INVALID;
    }
    if (action.meta.status === 413) {
      return COMMENT_TOO_LARGE;
    }
    if (action.meta.status === 429) {
      return COMMENT_LIMIT_REACHED;
    }
  },
  [UPDATE_COMMENT_FAILURE]: action => {
    if (action.meta.status === 412) {
      return COMMENT_INVALID;
    }
    if (action.meta.status === 413) {
      return COMMENT_TOO_LARGE;
    }
  },
  [INCREMENT_POST_LIKE_COUNT_SUCCESS]: () => POST_LIKE_SUCCESS,
  [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: () => COMMENT_INCREMENT_LIKE_COUNT_SUCCESS,
  [CREATE_COMMENT_SUCCESS]: () => COMMENT_CREATE_SUCCESS,
  [CREATE_POST_SUCCESS]: () => POST_CREATE_SUCCESS,
  [FETCH_SEARCH_SUCCESS]: () => SEARCH_SEARCH_SUCCESS,
  [UPDATE_CATEGORY_RANKS_SUCCESS]: () => CATEGORY_RANKS_UPDATE_SUCCESS,
};
