import createAction from '../../common/services/create-action';

export const FETCH_LOCAL_STORAGE_REQUEST = 'localStorage/FETCH_REQUEST';
export const FETCH_LOCAL_STORAGE_FAILURE = 'localStorage/FETCH_FAILURE';
export const FETCH_LOCAL_STORAGE_SUCCESS = 'localStorage/FETCH_SUCCESS';

export const fetchLocalStorageRequest = createAction(FETCH_LOCAL_STORAGE_REQUEST);
export const fetchLocalStorageFailure = createAction(FETCH_LOCAL_STORAGE_FAILURE);
export const fetchLocalStorageSuccess = createAction(FETCH_LOCAL_STORAGE_SUCCESS);

export const fetchLocalStorage = payload => dispatch => dispatch(fetchLocalStorageRequest(payload));
