import { RootState } from '../types/store-types';
import { syncPager } from '../actions/comments-pagination-actions';
import { initializeCommentsController } from '@wix/comments-ooi-client/controller';
import { shouldShowCaptcha } from '../actions/create-comment';
import {
  userEventsCommentCaptchaShown,
  userEventsCommentCaptchaResolved,
} from '../actions/user-events';
import { getSpamWords, getFilteredWords } from '../selectors/forum-data-selectors';
import { RicosContent } from 'ricos-content';
import { Awaited } from '../../utils/ts-utils';
import { setPostSeoTags } from '../actions/set-post-seo-tags';

type PostPagePaginationParamsStore = {
  value: any;
  update: (newValue: any) => void;
};

export const configureCommentsApi = ({
  wixCommentsApi,
  wixCodeApi,
  store,
  postPagePaginationParamsStore,
}: {
  wixCommentsApi: Awaited<ReturnType<typeof initializeCommentsController>>;
  wixCodeApi: any;
  store: RootState;
  postPagePaginationParamsStore: PostPagePaginationParamsStore;
}) => {
  wixCommentsApi.watch.pagination.onChange(paginationState => {
    store.dispatch(syncPager(paginationState, postPagePaginationParamsStore?.value?.params));
    store.dispatch(
      setPostSeoTags(paginationState, postPagePaginationParamsStore?.value?.params, wixCommentsApi),
    );
  });

  wixCommentsApi.intercept.onBeforeCrud(async operation => {
    const state = store.getState();

    if (shouldShowCaptcha(state, operation.content)) {
      const token = await getCaptchaToken();

      if (!token) {
        return {
          type: 'ERROR',
          message: { type: 'SILENT' },
        };
      }
    }

    const spamWords: string[] = getSpamWords(state);

    if (contentContainsSpamWords(operation.content, spamWords)) {
      return {
        type: 'ERROR',
        message: {
          type: 'EXTERNAL_TRANSLATION',
          key: 'messages.comment-invalid',
        },
      };
    }

    const filteredWords: string[] = getFilteredWords(state);

    return {
      type: 'OK',
      content: replaceContentWithFilteredWords(operation.content, filteredWords),
    };
  });

  const getCaptchaToken = async (): Promise<string> => {
    store.dispatch(userEventsCommentCaptchaShown());
    const token = await wixCodeApi.authentication.openCaptchaChallenge();
    store.dispatch(userEventsCommentCaptchaResolved(!!token));

    return token;
  };

  const contentContainsSpamWords = (content: RicosContent, spamWords: string[]): boolean => {
    return content.blocks.some(b => spamWords.some(word => b.text.includes(word)));
  };

  const replaceContentWithFilteredWords = (
    content: RicosContent,
    filteredWords: string[],
  ): RicosContent => {
    return {
      ...content,
      blocks: content.blocks.map(b => {
        return {
          ...b,
          text: b.text
            .split(/\b/)
            .map(word => {
              if (filteredWords.indexOf(word.toLowerCase()) > -1) {
                return '*'.repeat(word.length);
              }
              return word;
            })
            .join(''),
        };
      }),
    };
  };
};
