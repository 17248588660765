import { MODAL_TYPE_MOVE_POST } from './move-post-modal/move-post-modal-type';
import { MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS } from './manage-category-members-modal/manage-category-members-modal-type';
import { MODAL_TYPE_POSTING_DISABLED } from './posting-disabled-modal/posting-disabled-modal-type';
import { movePostModalDataLoader } from './move-post-modal/move-post-modal-data-loader';
import { manageCategoryMembersPlansModalDataLoader } from './manage-category-members-modal/manage-category-members-plans-modal-data-loader';
import { postingDisabledModalDataLoader } from './posting-disabled-modal/posting-disabled-modal-data-loader';

export const modalDataLoaderByType = {
  [MODAL_TYPE_MOVE_POST]: movePostModalDataLoader,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS]: manageCategoryMembersPlansModalDataLoader,
  [MODAL_TYPE_POSTING_DISABLED]: postingDisabledModalDataLoader,
};
