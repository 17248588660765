import createAction from '../../common/services/create-action';
import { isCacheableRendering } from '../../common/store/basic-params/basic-params-selectors';

export const FETCH_COUNTERS_REQUEST = 'counters/FETCH_REQUEST';
export const FETCH_COUNTERS_SUCCESS = 'counters/FETCH_SUCCESS';
export const FETCH_COUNTERS_FAILURE = 'counters/FETCH_FAILURE';

export const fetchCountersRequest = createAction(FETCH_COUNTERS_REQUEST);
export const fetchCountersSuccess = createAction(
  FETCH_COUNTERS_SUCCESS,
  payload => payload,
  () => ({
    throttle: {
      batch: 'counters',
      config: {
        debounce: 1500,
        invokeAsBatch: true,
        options: {
          maxWait: 2000,
        },
      },
    },
  }),
);
export const fetchCountersFailure = createAction(FETCH_COUNTERS_FAILURE);

export function fetchCounters({ categoryIds, postIds }) {
  return (dispatch, getState, { request }) => {
    if (isCacheableRendering(getState())) {
      return Promise.resolve();
    }

    dispatch(fetchCountersRequest());

    if ((categoryIds && categoryIds.length === 0) || (postIds && postIds.length === 0)) {
      return Promise.resolve();
    }

    const promise = request.post(`/counters`, { categoryIds, postIds });
    return promise
      .then(counters => dispatch(fetchCountersSuccess(counters)))
      .catch(() => dispatch(fetchCountersFailure()))
      .then(() => promise);
  };
}
