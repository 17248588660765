import { get } from 'lodash';
import { FETCH_LIKES_DATA_SUCCESS, RESET_LIKES_DATA } from '../actions/who-liked-actions';

export function whoLiked(state = {}, action) {
  switch (action.type) {
    case FETCH_LIKES_DATA_SUCCESS: {
      const id = action.payload.commentId || action.payload.postId;
      return {
        ...state,
        [id]: {
          lastPage: action.payload.page,
          items: get(state, `${id}.items`, []).concat(action.payload.data),
        },
      };
    }
    case RESET_LIKES_DATA: {
      const id = action.payload.commentId || action.payload.postId;
      const nextState = { ...state };
      delete nextState[id];
      return nextState;
    }
    default:
      return state;
  }
}
