import createAction from '../../common/services/create-action';

export const FETCH_SITE_ID_REQUEST = 'siteId/FETCH_REQUEST';
export const FETCH_SITE_ID_SUCCESS = 'siteId/FETCH_SUCCESS';
export const FETCH_SITE_ID_FAILURE = 'siteId/FETCH_FAILURE';

export const fetchSiteIdRequest = createAction(FETCH_SITE_ID_REQUEST);
export const fetchSiteIdSuccess = createAction(FETCH_SITE_ID_SUCCESS);
export const fetchSiteIdFailure = createAction(FETCH_SITE_ID_FAILURE);

export function fetchSiteId() {
  return (dispatch, getState, { request }) => {
    dispatch(fetchSiteIdRequest());

    const promise = request(`/autopilot/siteid`);
    return promise
      .then(
        response => dispatch(fetchSiteIdSuccess(response.siteId)),
        dispatch(fetchSiteIdFailure()),
      )
      .then(() => promise);
  };
}
