import { showMessage } from '../../common/messages/framework/store/message-actions';
import { POST_UNPIN } from '../components/messages/message-types';

export const UNPIN_POST_REQUEST = 'post/UNPIN_REQUEST';
export const UNPIN_POST_SUCCESS = 'post/UNPIN_SUCCESS';
export const UNPIN_POST_FAILURE = 'post/UNPIN_FAILURE';

export function unpinPost(id) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: UNPIN_POST_REQUEST,
    });

    const promise = request.post(`/posts/${id}/unpin`);

    return promise
      .then(post => {
        dispatch(showMessage(POST_UNPIN));
        return dispatch({
          type: UNPIN_POST_SUCCESS,
          payload: post,
        });
      })
      .catch(() =>
        dispatch({
          type: UNPIN_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
