import { showMessage } from '../../common/messages/framework/store/message-actions';
import {
  POST_DISABLE_COMMENTING,
  POST_DISABLE_ANSWERING,
} from '../components/messages/message-types';
import { isDiscussion } from '@wix/communities-forum-client-commons';

export const DISABLE_POST_COMMENTS_REQUEST = 'post/DISABLE_COMMENTS_REQUEST';
export const DISABLE_POST_COMMENTS_SUCCESS = 'post/DISABLE_COMMENTS_SUCCESS';
export const DISABLE_POST_COMMENTS_FAILURE = 'post/DISABLE_COMMENTS_FAILURE';

export function disablePostComments(id, postType) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: DISABLE_POST_COMMENTS_REQUEST,
    });

    const promise = request.post(`/posts/${id}/disableComments`);

    return promise
      .then(post => {
        dispatch(
          showMessage(isDiscussion(postType) ? POST_DISABLE_COMMENTING : POST_DISABLE_ANSWERING),
        );
        return dispatch({
          type: DISABLE_POST_COMMENTS_SUCCESS,
          payload: post,
        });
      })
      .catch(() =>
        dispatch({
          type: DISABLE_POST_COMMENTS_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
