import * as WS_EVENTS from '../../universal/constants/ws-events';
import { fetchComment } from './fetch-comment';

export const SOCKET_NEW_COMMENT = WS_EVENTS.NEW_COMMENT;

export const newComment = payload => dispatch => {
  dispatch(
    fetchComment({ postId: payload.postId, postType: payload.postType, commentId: payload._id }),
  );
};
