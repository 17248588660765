import { unsubscribe } from './unsubscribe';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { CATEGORY_UNSUBSCRIBED } from '../components/messages/message-types';

export function unsubscribeFromCategory(_id) {
  return dispatch =>
    dispatch(unsubscribe({ _id, type: 'category' })).then(() =>
      dispatch(showMessage(CATEGORY_UNSUBSCRIBED)),
    );
}
