import createAction from '../../common/services/create-action';

export const FETCH_RELATED_POSTS_REQUEST = 'related-posts/FETCH_REQUEST';
export const FETCH_RELATED_POSTS_SUCCESS = 'related-posts/FETCH_SUCCESS';
export const FETCH_RELATED_POSTS_FAILURE = 'related-posts/FETCH_FAILURE';

export const fetchRelatedPostsRequest = createAction(FETCH_RELATED_POSTS_REQUEST);
export const fetchRelatedPostsSuccess = createAction(FETCH_RELATED_POSTS_SUCCESS);
export const fetchRelatedPostsFailure = createAction(FETCH_RELATED_POSTS_FAILURE);

export function fetchRelatedPosts(postId) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchRelatedPostsRequest());

    const promise = request(`/search/related/${postId}`);
    return promise
      .then(
        results => dispatch(fetchRelatedPostsSuccess(results.posts)),
        dispatch(fetchRelatedPostsFailure()),
      )
      .then(() => promise);
  };
}
