import createAction from '../../services/create-action';
import { SITE_MEMBERS_PAGINATION_FETCH_LIMIT } from './site-members-constants';

export const FETCH_SITE_MEMBERS_LIST_REQUEST = 'site-members/FETCH_REQUEST';
export const FETCH_SITE_MEMBERS_LIST_SUCCESS = 'site-members/FETCH_SUCCESS';
export const FETCH_SITE_MEMBERS_LIST_FAILURE = 'site-members/FETCH_FAILURE';

export const SITE_MEMBERS_RESET = 'site-members/RESET';

export const fetchSiteMembersListRequest = createAction(FETCH_SITE_MEMBERS_LIST_REQUEST);
export const fetchSiteMembersListSuccess = createAction(
  FETCH_SITE_MEMBERS_LIST_SUCCESS,
  payload => payload,
);
export const fetchSiteMembersListFailure = createAction(FETCH_SITE_MEMBERS_LIST_FAILURE);

export const fetchSiteMembersList = ({ query = '', offset }) => {
  return (dispatch, getState, { siteMembersRequest, membersGroupsRequest }) => {
    dispatch(fetchSiteMembersListRequest({ query, offset }));

    const promise = siteMembersRequest.post('/v1/members/query', {
      query: JSON.stringify({
        filter: { nickname: { $contains: query } },
        paging: { limit: SITE_MEMBERS_PAGINATION_FETCH_LIMIT, offset },
      }),
    });
    return promise
      .then(
        res => {
          if (res.members.length > 0) {
            membersGroupsRequest // decorate with groups
              .post(`/v1/groups/users/listGroups`, {
                userIds: res.members.map(r => r.id),
              })
              .then(r => {
                res.members.forEach(member => {
                  const g = r.usersGroups[member.id];
                  const groups = g ? g.group : [];
                  member.groups = groups.map(g => g.id);
                });
                dispatch(fetchSiteMembersListSuccess({ ...res, query }));
              });
          } else {
            dispatch(fetchSiteMembersListSuccess({ ...res, query }));
          }
        },
        () => dispatch(fetchSiteMembersListFailure()),
      )
      .then(() => promise);
  };
};

export function resetSiteMembersList() {
  return {
    type: SITE_MEMBERS_RESET,
  };
}
