import createAction from '../../common/services/create-action';

export const SET_LOCAL_STORAGE_ITEM_REQUEST = 'localStorageItem/SET_REQUEST';
export const SET_LOCAL_STORAGE_ITEM_FAILURE = 'localStorageItem/SET_FAILURE';
export const SET_LOCAL_STORAGE_ITEM_SUCCESS = 'localStorageItem/SET_SUCCESS';

export const setLocalStorageItemRequest = createAction(SET_LOCAL_STORAGE_ITEM_REQUEST);
export const setLocalStorageItemFailure = createAction(SET_LOCAL_STORAGE_ITEM_FAILURE);
export const setLocalStorageItemSuccess = createAction(SET_LOCAL_STORAGE_ITEM_SUCCESS);

export const setLocalStorageItem = payload => dispatch =>
  dispatch(setLocalStorageItemRequest(payload));
