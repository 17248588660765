import createAction from '../../common/services/create-action';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';

export const FETCH_MEMBERS_GROUPS_LIST_REQUEST = 'members-groups-list/FETCH_REQUEST';
export const FETCH_MEMBERS_GROUPS_LIST_SUCCESS = 'members-groups-list/FETCH_SUCCESS';
export const FETCH_MEMBERS_GROUPS_LIST_FAILURE = 'members-groups-list/FETCH_FAILURE';

export const fetchMembersGroupsListRequest = createAction(FETCH_MEMBERS_GROUPS_LIST_REQUEST);
export const fetchMembersGroupsListSuccess = createAction(
  FETCH_MEMBERS_GROUPS_LIST_SUCCESS,
  payload => payload,
);
export const fetchMembersGroupsListFailure = createAction(FETCH_MEMBERS_GROUPS_LIST_FAILURE);

export const fetchMembersGroupsList = () => {
  return (dispatch, getState, { membersGroupsRequest }) => {
    dispatch(fetchMembersGroupsListRequest());

    const promise = membersGroupsRequest('/v1/groups');
    const promiseCounts = membersGroupsRequest('/v1/groups/count');
    return Promise.all([promise, promiseCounts])
      .then(
        ([resGroups, resCounts]) => {
          dispatch(
            fetchMembersGroupsListSuccess(
              resGroups.groups.map(group => {
                group.membersCount = resCounts.idToTotalUsers[group.id];
                return group;
              }),
            ),
          );
        },
        () => dispatch(fetchMembersGroupsListFailure()),
      )
      .then(() => promise);
  };
};

export const fetchMembersGroupsListPromisified = createPromisifiedAction(
  fetchMembersGroupsList,
  () => null,
  () => null,
);
