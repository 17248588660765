import createAction from '../../common/services/create-action';

export const ASSIGN_MEMBERS_TO_GROUP_REQUEST = 'assign-members-to-group/FETCH_REQUEST';
export const ASSIGN_MEMBERS_TO_GROUP_SUCCESS = 'assign-members-to-group/FETCH_SUCCESS';
export const ASSIGN_MEMBERS_TO_GROUP_FAILURE = 'assign-members-to-group/FETCH_FAILURE';

export const assignMembersToGroupRequest = createAction(ASSIGN_MEMBERS_TO_GROUP_REQUEST);
export const assignMembersToGroupSuccess = createAction(
  ASSIGN_MEMBERS_TO_GROUP_SUCCESS,
  payload => payload,
);
export const assignMembersToGroupFailure = createAction(ASSIGN_MEMBERS_TO_GROUP_FAILURE);

export const assignMembersToGroup = (groupId, listOfSiteMemberIds) => {
  return (dispatch, getState, { membersGroupsRequest }) => {
    dispatch(assignMembersToGroupRequest());

    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    endDate.setFullYear(endDate.getFullYear() + 15);

    const promise = membersGroupsRequest.post(`/v1/groups/${groupId}/users`, {
      userIds: listOfSiteMemberIds,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
    return promise
      .then(
        () => dispatch(assignMembersToGroupSuccess({ groupId, listOfSiteMemberIds })),
        () => dispatch(assignMembersToGroupFailure()),
      )
      .then(() => promise);
  };
};
