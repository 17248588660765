import createAction from '../../common/services/create-action';
import { omitProhibitedUpdateFields } from '../services/categories-service';

export const UPDATE_CATEGORY_REQUEST = 'category/UPDATE_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'category/UPDATE_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'category/UPDATE_FAILURE';

export const updateCategoryRequest = createAction(UPDATE_CATEGORY_REQUEST);
export const updateCategorySuccess = createAction(UPDATE_CATEGORY_SUCCESS);
export const updateCategoryFailure = createAction(UPDATE_CATEGORY_FAILURE);

export function updateCategory(categoryId, data) {
  return (dispatch, getState, { request }) => {
    dispatch(updateCategoryRequest());

    const promise = request.patch(`/categories/${categoryId}`, omitProhibitedUpdateFields(data));
    return promise
      .then(updatedCategory => dispatch(updateCategorySuccess(updatedCategory)))
      .catch(() => dispatch(updateCategoryFailure()))
      .then(() => promise);
  };
}
