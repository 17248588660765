import { subscribeToCategory } from '../actions/subscribe-to-category';
import { subscribeToPost } from '../actions/subscribe-to-post';
import { createComment, createCommentReply } from '../actions/create-comment';
import { upvoteComment, downvoteComment } from '../actions/vote-comment';
import { incrementCommentLikeCount } from '../actions/increment-comment-like-count';
import { incrementPostLikeCount } from '../../common/actions/increment-post-like-count';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getCategory } from '../../common/selectors/categories-selectors';
import { getPost } from '../selectors/post-selectors';
import {
  CREATE_NEW_POST,
  FOLLOW_CATEGORY,
  FOLLOW_POST,
  LIKE_COMMENT,
  LIKE_POST,
  CREATE_COMMENT,
  CREATE_REPLY,
  CREATE_NEW_QUESTION,
  UPVOTE_COMMENT,
  DOWNVOTE_COMMENT,
  GO_TO_MY_POSTS,
} from '../constants/interactions';
import { getSavedAction } from '../../common/store/saved-action/saved-action-selector';

export function invokeSavedAction(store) {
  const state = store.getState();
  const { action, args } = getSavedAction(state);
  switch (action) {
    case FOLLOW_CATEGORY:
      const category = getCategory(state, ...args);
      if (!category.isSubscribed) {
        store.dispatch(subscribeToCategory(...args));
      }
      return;
    case FOLLOW_POST:
      const post = getPost(state, ...args);
      if (!post.isSubscribed) {
        store.dispatch(subscribeToPost(...args));
      }
      return;
    case LIKE_COMMENT:
      store.dispatch(incrementCommentLikeCount(...args));
      return;
    case LIKE_POST:
      store.dispatch(incrementPostLikeCount(...args));
      return;
    case CREATE_NEW_POST:
      store.dispatch(navigateWithinForum(...args));
      return;
    case CREATE_NEW_QUESTION:
      store.dispatch(navigateWithinForum(...args));
      return;
    case GO_TO_MY_POSTS:
      store.dispatch(navigateWithinForum(args));
      return;
    case CREATE_COMMENT:
      store.dispatch(createComment(...args));
      return;
    case CREATE_REPLY:
      store.dispatch(createCommentReply(...args));
      return;
    case UPVOTE_COMMENT:
      store.dispatch(upvoteComment({ postId: args[0], commentId: args[1] }));
      return;
    case DOWNVOTE_COMMENT:
      store.dispatch(downvoteComment({ postId: args[0], commentId: args[1] }));
      return;
    default:
      return;
  }
}
