export function getDeepLinkParams(deepLinkData) {
  let commentToFocusId = null;
  let parentId = null;
  let repliesPage = null;
  if (deepLinkData) {
    const deepLinkParts = deepLinkData.split('-');
    commentToFocusId = deepLinkParts[0];
    parentId = deepLinkParts[1] ? deepLinkParts[1] : null;
    repliesPage = deepLinkParts[2] ? parseInt(deepLinkParts[2], 10) : null;
  }

  return { commentToFocusId, parentId, repliesPage };
}
