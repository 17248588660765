import { subscribeChannel, unsubscribeChannel } from './duplexer-actions';
import { DUPLEXER_CHANNELS } from '../../universal/constants/duplexer';

export const emitOpenPost = postId => dispatch => {
  dispatch(subscribeChannel({ channelName: DUPLEXER_CHANNELS.POST, resourceId: postId }));
};

export const emitClosePost = postId => dispatch => {
  dispatch(unsubscribeChannel({ channelName: DUPLEXER_CHANNELS.POST, resourceId: postId }));
};
