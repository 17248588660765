import { SET_IS_LOADED } from '../actions/set-is-loaded';
import {
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
} from '../actions/fetch-comments-constants';
import { FETCH_POSTS_SUCCESS, FETCH_POSTS_FAILURE } from '../actions/fetch-posts';
import { FETCH_POST_SUCCESS, FETCH_POST_FAILURE } from '../actions/fetch-post';
import {
  FETCH_POST_PAGE_DATA_SUCCESS,
  FETCH_POST_PAGE_DATA_FAILURE,
} from '../actions/fetch-post-page-data';
import { FETCH_CATEGORIES_SUCCESS } from '../../common/actions/fetch-categories';

export default function isLoaded(state = {}, action) {
  switch (action.type) {
    case SET_IS_LOADED:
      return setIsLoaded(state, action.payload);
    case FETCH_POST_SUCCESS:
    case FETCH_POST_FAILURE:
      return setIsLoaded(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoaded: true,
      });
    case FETCH_POST_PAGE_DATA_FAILURE:
    case FETCH_POST_PAGE_DATA_SUCCESS: {
      let nextState = setIsLoaded(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoaded: true,
      });

      nextState = setIsLoaded(nextState, {
        entity: 'comments',
        id: action.payload.postSlug,
        isLoaded: true,
      });

      return nextState;
    }
    case FETCH_POSTS_SUCCESS:
    case FETCH_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'posts',
        id: action.meta.categoryId,
        isLoaded: true,
      });

    case FETCH_COMMENTS_SUCCESS:
    case FETCH_COMMENTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'comments',
        id: action.payload.postSlug,
        isLoaded: true,
      });
    case FETCH_CATEGORIES_SUCCESS:
      return setIsLoaded(state, {
        entity: 'categories',
        isLoaded: true,
      });
    default:
      return state;
  }
}

function setIsLoaded(state, { entity, id = '', isLoaded }) {
  return {
    ...state,
    [entity]: {
      ...state[entity],
      [id]: isLoaded,
    },
  };
}
