import { FETCH_MEMBERS_GROUPS_LIST_SUCCESS } from '../actions/fetch-members-groups-list';

export function membersGroups(state = [], action) {
  switch (action.type) {
    case FETCH_MEMBERS_GROUPS_LIST_SUCCESS:
      return action.payload.map(group => {
        group.type = group.type || 'role';
        return group;
      });
    default:
      return state;
  }
}
