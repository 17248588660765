import { showMessage } from '../../common/messages/framework/store/message-actions';
import { POST_PIN } from '../components/messages/message-types';

export const PIN_POST_REQUEST = 'post/PIN_REQUEST';
export const PIN_POST_SUCCESS = 'post/PIN_SUCCESS';
export const PIN_POST_FAILURE = 'post/PIN_FAILURE';

export function pinPost(id) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: PIN_POST_REQUEST,
    });

    const promise = request.post(`/posts/${id}/pin`);

    return promise
      .then(post => {
        dispatch(showMessage(POST_PIN));
        return dispatch({
          type: PIN_POST_SUCCESS,
          payload: post,
        });
      })
      .catch(() =>
        dispatch({
          type: PIN_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
