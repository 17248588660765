import {
  initClassicPaginationUtils,
  parsePageParam,
} from '@wix/comments-ooi-client/classic-pagination';
import { Ordering } from '@wix/comments-ooi-client/controller';

const ShorthandsByOrdering = {
  [Ordering.LATEST_FIRST]: 'l',
  [Ordering.OLDEST_FIRST]: 'o',
  [Ordering.MOST_VOTES_FIRST]: 'm',
  [Ordering.LEAST_VOTES_FIRST]: 'f',
};

const stringifyPageParam = (pageParam, paginationConfig) => {
  const pages =
    pageParam.first === pageParam.last
      ? `${pageParam.first}`
      : `${pageParam.first}-${pageParam.last}`;

  if (pageParam.ordering === paginationConfig.ordering) {
    return pages;
  }
  return `${pages}~${ShorthandsByOrdering[pageParam.ordering]}`;
};

const classicPagintationUtils = initClassicPaginationUtils({
  parsePageParam,
  stringifyPageParam,
  pageParamName: 'page',
});

export default classicPagintationUtils;
