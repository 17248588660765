import { subscribe } from './subscribe';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { CATEGORY_SUBSCRIBED } from '../components/messages/message-types';

export function subscribeToCategory(_id) {
  return dispatch =>
    dispatch(subscribe({ _id, type: 'category' })).then(() =>
      dispatch(showMessage(CATEGORY_SUBSCRIBED)),
    );
}
