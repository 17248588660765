import createAction from '../../common/services/create-action';

export const DELETE_MEMBERS_FROM_GROUP_REQUEST = 'delete-members-from-group/FETCH_REQUEST';
export const DELETE_MEMBERS_FROM_GROUP_SUCCESS = 'delete-members-from-group/FETCH_SUCCESS';
export const DELETE_MEMBERS_FROM_GROUP_FAILURE = 'delete-members-from-group/FETCH_FAILURE';

export const deleteMembersFromGroupRequest = createAction(DELETE_MEMBERS_FROM_GROUP_REQUEST);
export const deleteMembersFromGroupSuccess = createAction(
  DELETE_MEMBERS_FROM_GROUP_SUCCESS,
  payload => payload,
);
export const deleteMembersFromGroupFailure = createAction(DELETE_MEMBERS_FROM_GROUP_FAILURE);

export const deleteMembersFromGroup = (groupId, listOfSiteMemberIds) => {
  return (dispatch, getState, { membersGroupsRequest }) => {
    dispatch(deleteMembersFromGroupRequest());

    const promise = membersGroupsRequest.post(`/v1/groups/${groupId}/users/delete`, {
      userIds: listOfSiteMemberIds,
    });
    return promise
      .then(
        () => dispatch(deleteMembersFromGroupSuccess({ groupId, listOfSiteMemberIds })),
        () => dispatch(deleteMembersFromGroupFailure()),
      )
      .then(() => promise);
  };
};
