import {
  URI as uri,
  buildPaginationRequestParams,
  getHeader,
} from '@wix/communities-forum-client-commons';
import createAction from '../../common/services/create-action';

import { getCommentsPerPage } from '../constants/pagination';
import { getCommentsIdsThatHaveReplies } from '../selectors/comment-selectors';
import { fetchInitialReplies } from './fetch-replies';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import {
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
} from './fetch-comments-constants';

export const fetchCommentsRequest = createAction(FETCH_COMMENTS_REQUEST);
export const fetchCommentsSuccess = createAction(
  FETCH_COMMENTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchCommentsFailure = createAction(FETCH_COMMENTS_FAILURE);

export const fetchComments = ({ postId, postSlug, postType, page, pageSize }) => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchCommentsRequest({ postId, postSlug, postType }));

    const params = buildPaginationRequestParams(page, pageSize);
    const promise = request(uri(`/posts/${postId}/comments`).query(params), {
      parseHeaders: true,
    });
    return promise
      .then(({ body = [], headers = {} }) => {
        const payload = {
          comments: body,
          postId,
          postSlug,
          postType,
        };
        const meta = {
          page,
          paginationId: postId,
          entityCount: parseInt(getHeader(headers, 'Wix-Forum-TotalResults'), 10),
        };
        const action = fetchCommentsSuccess(payload, meta);
        return dispatch(action);
      })
      .catch(() => dispatch(fetchCommentsFailure({ postSlug })))
      .then(() => promise);
  };
};

export const fetchCommentsWithReplies = (post, postSlug, page) => (dispatch, getState) => {
  return dispatch(
    fetchComments({
      postId: post._id,
      postSlug,
      postType: post.postType,
      pageSize: getCommentsPerPage(getIsMobile(getState())),
      page,
    }),
  ).then(comments => {
    const commentsIds = getCommentsIdsThatHaveReplies(comments.body);
    if (commentsIds.length) {
      return dispatch(
        fetchInitialReplies({
          commentsIds,
          postId: post._id,
          postSlug,
        }),
      );
    }
  });
};
