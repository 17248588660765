module.exports = {
  NEW_POST: 'new post',
  UPDATE_POST_COUNTERS: 'update post counters',
  UPDATE_CATEGORY_COUNTERS: 'update category counters',
  NEW_COMMENT: 'new comment',
  TYPING_MESSAGE: 'typing',

  NEW_NOTIFICATION: 'new notification',
  USER_PERMISSIONS_CHANGED: 'user permissions changed',
  USER_UPDATED: 'user updated',
};
