import { pick, get, isArray } from 'lodash';
import * as blobUtil from 'blob-util';
import { TYPE_IMAGE } from './media-types';

const IMAGE_PROPERTIES = ['width', 'height', 'file_name', 'original_file_name'];

export default function uploadFile({
  file,
  name,
  type,
  credentials: { uploadToken, uploadUrl },
  folderId,
}) {
  const formData = new FormData();

  let blob = file;
  try {
    blob = blobUtil.dataURLToBlob(blob);
  } catch (e) {}
  formData.append('file', blob, name);
  formData.append('upload_token', uploadToken);
  formData.append('parent_folder_id', folderId);
  formData.append('media_type', type);

  const request = new XMLHttpRequest();
  const promise = new Promise((resolve, reject) => {
    try {
      request.responseType = 'json'; // IE11 does not support responseType json
    } catch (error) {}
    request.addEventListener('load', event => {
      let data = isArray(event.target.response) ? event.target.response[0] : event.target.response;

      try {
        data = JSON.parse(event.target.response)[0];
      } catch (error) {}

      if (event.target.status === 406) {
        reject(event.target.status);
      }

      if (type === TYPE_IMAGE) {
        data = pick(data, IMAGE_PROPERTIES);
      }

      resolve(data);
    });
    request.addEventListener('error', reject);
    request.open('POST', uploadUrl);
    request.send(formData);
  });

  return {
    xhr: request,
    promise,
  };
}

export function uploadFileV2({ file, name, path, acl, credentials: { uploadToken, uploadUrl } }) {
  const formData = new FormData();

  let blob = file;
  try {
    blob = blobUtil.dataURLToBlob(blob);
  } catch (e) {}
  formData.append('file', blob, name);
  formData.append('uploadToken', uploadToken);
  formData.append('acl', acl);
  formData.append('path', path);

  const request = new XMLHttpRequest();
  const promise = new Promise((resolve, reject) => {
    try {
      request.responseType = 'json'; // IE11 does not support responseType json
    } catch (error) {}
    request.addEventListener('load', event => {
      const data = get(event, 'target.response.payload.0', get(event, 'target.response.payload'));
      resolve(data);
    });
    request.addEventListener('error', reject);
    request.open('POST', uploadUrl);
    request.send(formData);
  });

  return promise;
}
