import { findIndex } from 'lodash';

export const METATAG_NAME_ROBOTS = 'robots';
export const METATAG_CONTENT_NOINDEX = 'noindex';

export const setMetaTagRobotsNoIndex = wixCodeApi => {
  const metaTags = wixCodeApi.seo.metaTags || [];
  const robotsIndex = findIndex(metaTags, tag => tag.name && tag.name === METATAG_NAME_ROBOTS);
  if (robotsIndex > -1) {
    metaTags[robotsIndex].content = METATAG_CONTENT_NOINDEX;
  } else {
    metaTags.push({ name: METATAG_NAME_ROBOTS, content: METATAG_CONTENT_NOINDEX });
  }
  wixCodeApi.seo.setMetaTags && wixCodeApi.seo.setMetaTags(metaTags);
};
