import {
  MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_ADD,
  MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_REMOVE,
} from '../actions/manage-category-members';
import { OPEN_MODAL, CLOSE_MODAL } from '../../common/modals/framework/store/modal-actions';
import { MODAL_TYPE_MANAGE_CATEGORY_MEMBERS } from '../components/modals/manage-category-members-modal/manage-category-members-modal-type';
import { CATEGORIES_MANAGER_EDIT_CATEGORY } from '../actions/categories-manager-actions-constants';
import { ASSIGN_MEMBERS_TO_GROUP_SUCCESS } from '../actions/assign-members-to-group';
import { DELETE_MEMBERS_FROM_GROUP_SUCCESS } from '../actions/delete-members-from-group';

const initialState = {
  addSiteMembersToCategoryGroup: [],
  removeSiteMembersFromCategoryGroup: [],
};

export function manageCategoryMembers(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      if (action.payload.type === MODAL_TYPE_MANAGE_CATEGORY_MEMBERS) {
        return { ...initialState, ...action.payload.props };
      }
      return state;
    }
    case MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_ADD: {
      let removeSiteMembersFromCategoryGroup = [...state.removeSiteMembersFromCategoryGroup];
      let addSiteMembersToCategoryGroup = [...state.addSiteMembersToCategoryGroup];
      for (const smId of action.payload.listOfSiteMemberIds) {
        if (removeSiteMembersFromCategoryGroup.includes(smId)) {
          removeSiteMembersFromCategoryGroup = removeSiteMembersFromCategoryGroup.filter(
            id => id !== smId,
          );
        } else {
          addSiteMembersToCategoryGroup = addSiteMembersToCategoryGroup.concat(smId);
        }
      }
      return {
        ...state,
        removeSiteMembersFromCategoryGroup,
        addSiteMembersToCategoryGroup,
      };
    }
    case MANAGE_CATEGORY_MEMBERS_SITE_MEMBERS_REMOVE: {
      let removeSiteMembersFromCategoryGroup = [...state.removeSiteMembersFromCategoryGroup];
      let addSiteMembersToCategoryGroup = [...state.addSiteMembersToCategoryGroup];
      for (const smId of action.payload.listOfSiteMemberIds) {
        if (addSiteMembersToCategoryGroup.includes(smId)) {
          addSiteMembersToCategoryGroup = addSiteMembersToCategoryGroup.filter(id => id !== smId);
        } else {
          removeSiteMembersFromCategoryGroup = removeSiteMembersFromCategoryGroup.concat(smId);
        }
      }
      return {
        ...state,
        removeSiteMembersFromCategoryGroup,
        addSiteMembersToCategoryGroup,
      };
    }
    case ASSIGN_MEMBERS_TO_GROUP_SUCCESS:
      return { ...state, addSiteMembersToCategoryGroup: [] };
    case DELETE_MEMBERS_FROM_GROUP_SUCCESS:
      return { ...state, removeSiteMembersFromCategoryGroup: [] };
    case CLOSE_MODAL: {
      return state;
    }
    case CATEGORIES_MANAGER_EDIT_CATEGORY:
      return initialState; // reset state when category editing starts
    default:
      return state;
  }
}
