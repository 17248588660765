import { EXTERNAL_HOOK_REJECT } from '../../app/components/messages/message-types';
import { showMessage } from '../messages/framework/store/message-actions';
import { isExternalHookReject } from './external-hooks';

export const createExternalHookRejectHandler = dispatch => response => {
  if (isExternalHookReject(response) && response.payload) {
    dispatch(
      showMessage(EXTERNAL_HOOK_REJECT, {
        message: response.payload,
      }),
    );
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({
    status: response,
  });
};
