import { PaginationState } from '@wix/comments-ooi-client/dist/types/classic-pagination';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { get } from 'lodash';
import { decoratePostWithSeoAttributes } from '@wix/forum-seo-entities-decorator';
import { getIsMobile, isSite } from '../../common/store/basic-params/basic-params-selectors';
import { getCategoryBySlug } from '../../common/selectors/categories-selectors';
import { getPostBySlug } from '../selectors/post-selectors';
import { initializeCommentsController } from '@wix/comments-ooi-client/controller';
import { Awaited } from '../../utils/ts-utils';
import { getForumSectionUrl } from '../../common/services/get-section-url';
import { getBaseUrl } from '../../common/store/location/location-selectors';
import { getProfileLinkTemplate } from '../../common/store/ma-navigation/ma-navigation-selectors';
import { buildLink } from '../../common/store/ma-navigation/build-link';
import { getExperiments } from '../selectors/experiments-selectors';
import { getCommentsPerPage } from '../constants/pagination';
import { buildNewDeepCommentUrl } from '../services/build-deep-comment-url';
import { RicosContent } from 'ricos-content';

type RouterParams = {
  postSlug?: string;
  categorySlug?: string;
  page?: string;
};

type WixCommentsApi = Awaited<ReturnType<typeof initializeCommentsController>>;

export const setPostSeoTags = (
  paginationState: PaginationState,
  routeParams: RouterParams,
  wixCommentsApi: WixCommentsApi,
) => (
  dispatch: AppDispatch,
  getState: () => RootState,
  { wixCodeApi }: { wixCodeApi: IWixAPI },
) => {
  const state = getState();
  const categorySlug = routeParams?.categorySlug;
  const postSlug = routeParams?.postSlug;

  if (!isSite(state)) {
    return;
  }

  const category = getCategoryBySlug(state, routeParams?.categorySlug);
  if (!category) {
    return;
  }
  const post = getPostBySlug(state, routeParams?.postSlug);
  if (!post || paginationState[post._id]?.type !== 'READY') {
    return;
  }

  const currentPage = parseInt(routeParams?.page || '1', 10);
  const sectionUrl = getForumSectionUrl(wixCodeApi);
  const baseUrl = getBaseUrl(state);
  const postOwnerSiteMemberId = get(post, 'owner.siteMemberId');
  const postOwnerSlug = get(post, 'owner.slug');
  const isMobile = getIsMobile(state);

  const postCommentsState = wixCommentsApi.watch.comments.getLoadedComments()[post._id];

  if (postCommentsState?.type !== 'READY') {
    return;
  }

  const comments =
    Object.values(postCommentsState.comments).map(c => ({
      upvoteCount: c.upvoteCount,
      createdDate: c.createdAt,
      content: getCommentContent(c.comment.text),
      _id: c.commentId,
      owner: c.memberProfile ? { name: c.memberProfile?.nickname } : null,
    })) || [];

  const seoModel = decoratePostWithSeoAttributes({
    post,
    postComments: comments,
    page: currentPage,
    sectionUrl,
    ownerProfileUrl: postOwnerSiteMemberId
      ? `${baseUrl}${buildLink(getProfileLinkTemplate(state), postOwnerSlug)}`
      : '',
    experiments: getExperiments(state),
    category,
    commentsPerPage: getCommentsPerPage(isMobile),
    buildDeepLink: commentId =>
      `${sectionUrl}${buildNewDeepCommentUrl(categorySlug, postSlug, commentId)}`,
  });
  wixCodeApi.seo.renderSEOTags(seoModel);
};

const getCommentContent = (
  text: { content?: string | undefined } | undefined,
): RicosContent | undefined => {
  try {
    const content = text?.content;
    return content ? JSON.parse(content) : undefined;
  } catch {
    return undefined;
  }
};
