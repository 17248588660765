const LIVE_TIME = 5000;
class BiLocationTracker {
  constructor() {
    this.state = {};
  }

  set = (location, action) => (this.state[action] = { location, timestamp: Date.now() });

  get = action =>
    this.state[action] && Date.now() - this.state[action].timestamp < LIVE_TIME
      ? this.state[action].location
      : null;
}

export default new BiLocationTracker();
