import createAction from '../../common/services/create-action';

export const DELETE_COMMENT_REQUEST = 'comment/DELETE_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'comment/DELETE_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'comment/DELETE_FAILURE';

export const deleteCommentRequest = createAction(DELETE_COMMENT_REQUEST);
export const deleteCommentSuccess = createAction(DELETE_COMMENT_SUCCESS);
export const deleteCommentFailure = createAction(DELETE_COMMENT_FAILURE);

export function deleteComment({ postId, commentId }) {
  return (dispatch, getState, { request }) => {
    dispatch(deleteCommentRequest());

    const promise = request.delete(`/posts/${postId}/comments/${commentId}`);

    return promise
      .then(() => dispatch(deleteCommentSuccess({ _id: commentId, postId })))
      .catch(() => dispatch(deleteCommentFailure()))
      .then(() => promise);
  };
}
