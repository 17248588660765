import { map, omit } from 'lodash';

export default function sanitizeContent({ content, ...rest }) {
  const blocks = map(content.blocks, removeRedundantTextAlignment);
  return {
    ...rest,
    content: {
      ...content,
      blocks,
    },
  };
}

/*
 Removes text alignment from atomic blocks, because when atomic block is in selection range it's possible to set it's data.textAlignment prop.
 */
function removeRedundantTextAlignment(block) {
  return block.type === 'atomic' ? omitTextAlignment(block) : block;
}

function omitTextAlignment(block) {
  return omit(block, 'data.textAlignment');
}
