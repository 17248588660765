import { FETCH_TRANSLATIONS_SUCCESS, SET_TRANSLATIONS } from './translations-actions';

export function translations(state = null, action) {
  switch (action.type) {
    case FETCH_TRANSLATIONS_SUCCESS:
    case SET_TRANSLATIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
