import { getPreviousMatches } from '../../common/router/router-selectors';
import { FeedType } from '../containers/header-navigation';
import { NavigationType } from '../constants/navigation-type';
import { MatchedRoute } from '../types/matched-route';
import { RootState } from '../types/store-types';

type RouteCheck = (route: MatchedRoute) => boolean;

interface CategoryNavigation {
  type: NavigationType.Category;
  payload: {
    name: string;
    page: number;
  };
}
interface FeedNavigation {
  type: NavigationType.Feed;
  payload: {
    name: FeedType;
    page: number;
  };
}

export const isComingToSamePageFromPost = (
  state: RootState,
  navigation: CategoryNavigation | FeedNavigation,
) => {
  const previousMatches = getPreviousMatches(state) as MatchedRoute[];
  const postSlug = previousMatches[1]?.params.postSlug;
  const categorySlug = previousMatches[1]?.params.categorySlug;
  if (!postSlug || !categorySlug || previousMatches.length < 3) {
    return false;
  }

  let getIsRouteCorrect: RouteCheck;
  const prevMatchesBeforePost = previousMatches.slice(2);
  const { name, page } = navigation.payload;
  if (navigation.type === NavigationType.Feed) {
    getIsRouteCorrect = route => {
      const routePage = route.params?.page ? Number(route.params?.page) : 1;
      const routeFeedType = route.queryParams?.feedType;

      return routeFeedType === name && routePage === page;
    };
  } else {
    getIsRouteCorrect = route => {
      const routePage = route.params?.page ? Number(route.params?.page) : 1;
      const routeCategorySlug = route.params?.categorySlug;
      const routePostSlug = route.params?.postSlug;

      return routeCategorySlug === name && routePage === page && !routePostSlug;
    };
  }

  return isComingFromSamePage(getIsRouteCorrect, prevMatchesBeforePost, postSlug, categorySlug);
};

const isComingFromSamePage = (
  getIsRouteCorrect: RouteCheck,
  prevMatchesBeforePost: MatchedRoute[],
  postSlug: string,
  categorySlug: string,
) => {
  for (const match of prevMatchesBeforePost) {
    const matchedPostSlug = match.params?.postSlug;
    const matchedCategorySlug = match.params?.categorySlug;
    if (matchedPostSlug === postSlug && matchedCategorySlug === categorySlug) {
      continue;
    }
    return getIsRouteCorrect(match);
  }
  return false;
};
