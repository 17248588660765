import { get, find, filter, isEmpty, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
// import sortPosts from '../services/sort-posts';
import { UNCATEGORIZED_POSTS } from '../reducers/pagination';
import { getPostEntityIdsForPage, getPostEntityIds } from './pagination-selectors';
import { getCounter } from './counters-selectors';

export const getPostMap = state => state.posts || {};
export const getPost = (state, postId) => getPostMap(state)[postId];
const getSlug = (state, slug) => slug;
const getSiteMemberId = (state, siteMemberId) => siteMemberId;

export const getPostBySlug = createSelector([getPostMap, getSlug], (posts, slug) =>
  find(posts, post => post.slug === slug || (post.slugs && post.slugs.indexOf(slug) > -1)),
);

export const getSortedPostsByCategoryIdAndSiteMemberId = (state, categoryId, siteMemberId) => {
  const posts = getPostMap(state);
  return getPostEntityIds(state, categoryId, siteMemberId).map(id => posts[id]);
};

export const getSortedAllPosts = state =>
  getSortedPostsByCategoryIdAndSiteMemberId(state, UNCATEGORIZED_POSTS);

export const getPostsByCategoryIdAndSiteMemberIdAndPage = createSelector(
  [getPostMap, getPostEntityIdsForPage],
  (posts, entityIds) => entityIds.map(id => posts[id]),
);

export const getPostsBySiteMemberId = createSelector(
  [getPostMap, getSiteMemberId],
  (posts, siteMemberId) => filter(posts, post => get(post, 'owner.siteMemberId') === siteMemberId),
);

export const getNewPosts = state => state.footerPosts || [];

export const getRelatedPosts = state => state.relatedPosts || [];

export const hasRelatedPosts = state => !isEmpty(getRelatedPosts(state));

export const getRelatedAndNewPosts = state => {
  let posts = getNewPosts(state);
  posts = [...getRelatedPosts(state), ...posts].slice(0, 3);
  return uniqBy(posts, '_id');
};

export const getExternalLinks = state => state.externalLinks || [];

export const getSimilarPosts = state => state.similarPosts || [];

export const getPostLikeCount = (state, postId) => {
  const counters = getCounter(state, postId);

  return get(counters, 'likeCount', get(getPost(state, postId), 'likeCount', 0));
};
