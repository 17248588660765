import {
  SEARCH_RESULTS_PER_PAGE_MOBILE,
  SEARCH_RESULTS_PER_PAGE_DESKTOP,
} from '../constants/pagination';

export const getSearchResult = state => state.search.result;
export const getSearchResultsTotal = state => state.search.metadata.total;
export const getSearchResultsCurrentPage = (state, isMobile) => {
  return (
    Math.floor(
      state.search.metadata.offset /
        (isMobile ? SEARCH_RESULTS_PER_PAGE_MOBILE : SEARCH_RESULTS_PER_PAGE_DESKTOP),
    ) + 1
  );
};
