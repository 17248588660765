import { addErrorState } from './debug-state-actions';
import { normalizeError } from '../../services/monitoring';
import { FORUM_NOT_INSTALLED_ERROR } from '../../../app/constants/errors';
import { SSR_SUCCESS } from '../../../app/constants/interactions';

export function handleError({
  store,
  actions,
  actionsPromisified,
  setProps,
  appParams,
  sentry,
  isSSR,
}) {
  return error => {
    if (error === FORUM_NOT_INSTALLED_ERROR) {
      const state = store.getState();

      // Just to track these cases somewhere
      actions.interactionStarted(SSR_SUCCESS);
      actions.interactionEnded(SSR_SUCCESS);

      return setProps({
        ...(state && { state }),
        ...(actions && { actions }),
        ...(actionsPromisified && { actionsPromisified }),
        cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
        workerError: {
          isSSR,
          error,
          wasWorkerErrorCaptured: true,
        },
        isSSR,
      });
    }

    let errorId;
    const state = store && store.getState();
    console.error('--- error in createForumAppController ---', error);
    const shouldCaptureToSentry = sentry !== undefined;
    if (shouldCaptureToSentry) {
      errorId = sentry.captureException(normalizeError(error));
    }
    setProps({
      ...(state && { state }),
      ...(actions && { actions }),
      ...(actionsPromisified && { actionsPromisified }),
      cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
      workerError: {
        isSSR,
        error:
          error && error.toString() !== '[object Object]'
            ? error.toString()
            : JSON.stringify(error),
        wasWorkerErrorCaptured: shouldCaptureToSentry,
        ...(errorId && { errorId }),
      },
      isSSR,
    });
    store && store.dispatch(addErrorState(error));
  };
}
