import { FORUM_SECTION_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig.dev';
import createAction from '../../services/create-action';
import { transformWixCodeLocation } from './transform-wix-code-location';
import { FORUM_APP_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig.prod';

export const SET_LOCATION = 'location/SET';

export const setLocation = createAction(SET_LOCATION);

export const initializeLocation = () => async (dispatch, getState, { wixCodeApi }) => {
  const { url } =
    (await wixCodeApi.site.getSectionUrl({
      sectionId: FORUM_SECTION_ID,
      appDefinitionId: FORUM_APP_ID,
    })) || {};
  const location = await transformWixCodeLocation(wixCodeApi, url);
  return dispatch(setLocation(location));
};
